body {
    font-size: 15px;
}

.title {
    text-align: center;
    font-size: 2rem;
}

.archive-video {
    position: relative;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}


.archive-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -0.5rem 5rem;

    .item {
        flex: 0 1 24.325rem;
        margin: 0.75rem 0.5rem;
    }
}

.videojs-wrap {
    max-width: 50rem;
    margin: 0 auto 5rem;
}


.img {
    width: 100%;
}
